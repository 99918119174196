.btn{
  cursor: pointer;
  text-transform: uppercase;
  font-weight:bold;
  letter-spacing: 3px;
}

.btn-primary {
  color: theme-color("secondary");
  @include hover {
    color: darken(theme-color("secondary"), 7.5%);
  }
}

.btn-sm{
	font-weight: 300;
	font-size: .75rem;
}
