.main-title{
  text-transform: uppercase;
  letter-spacing: 4px;
}

.yellowBorder{
  border-bottom: 2px solid $yellow;
}

.brownBorder{
  border-bottom: 4px solid $light_brown;
}

.navigation-item{
  text-transform: uppercase;
  font-weight:bold;
  border-bottom: 2px solid transparent;

  &.active{
    @extend  .yellowBorder;
  }

  &:hover{
    color: $brown;
    cursor: pointer;
    @extend  .yellowBorder;
  }
}

.logo{

  img{ max-width: 160px; }

  &-small{
    img{
      max-width: 100px;
    }
  }

}

form select.form-control{
  border: 1px solid #000;
}

.form-check-label{
  line-height: normal;
}

.custom-check{
  border: 1px solid $gray;
  margin-right: .5rem;
  padding: .5rem;
  text-align: center;
  width: 80px;

  .truncate{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }

}

.subFolderItem{
  .dashItem{
    height:250px;
    .contents{
      width:75%;
      height:59%;
      overflow: hidden;
    }
  }
}

$thumbSize: 75px;
$hoverScale: 120px;

.downloadSingleFormHeader > div {
  width: 100%;
}

.downloadSingleFormName,
.downloadSingleFormDescription {
  text-align: right;
}

.downloadSingleFormDownload {
  padding-left: 33px;
}

.fileContainer{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 417px;
  .thumbImage{
    height: $thumbSize;
    width : $thumbSize;
    max-width: 100%;
    display:inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    &:hover{
      width : $hoverScale;
      height: $hoverScale;
    }
    &.noIcon{
      font-size: 1.7rem;
      color:$brown;
      &:hover{
        width: $thumbSize;
        height: $thumbSize;
        font-size: 2.5rem;
      }
    }
  }
}


.loadContainer {
  .fileContainer{
    .thumbImage{
      display:block;
      margin: 0 auto;
    }
  }
}

.mosaic{

  &>div{
    padding:5px;
    overflow: hidden;
  }

  .mosaicItem{
    background-repeat: no-repeat;
    background-position: center;
    display:block;
    width:100%;
    position:relative;
  }
}

.shadowLayer{
  box-shadow: inset 0 0 0 1000px rgba($black, 0.4);
}
.form-control{
  padding: .5rem .75rem;
}

.modal-open .modal.modal-center {
  display: flex!important;
  align-items: center!important;
  .modal-dialog {
    flex-grow: 1;
  }
}
.botton-line{
  position: relative;
  &:after{
    display: block;
    content: '';
    height: 2px;
    width: 45px;
    margin: 10px auto;
    background-color: theme-color("primary");
  }
}
.modal.show .modal-dialog{
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
}
.close{
  font-size: 2.5rem;
  position: absolute;
  right: 10px;
  z-index: 3;
}
