$light_brown: #DAC7AA;
$brown: #4B2F25;

$theme-colors: (
  primary: $light_brown,
  secondary:  $brown,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
) ;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 10% ;

$enable-rounded: false ;

$body-color:    #72706E ;

$link-color:            theme-color("secondary");
$link-decoration:       none;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;

$line-height-base: 1.66 ;

$grid-gutter-width: 2.15em ;

$font-family-base: 'Raleway', sans-serif;

$input-btn-padding-y:       .68rem;
$input-btn-padding-x:       3.5rem;

$input-btn-padding-y-sm:    .25rem;
$input-btn-padding-x-sm:    1.5rem;

$input-border-color:        $light_brown;
$input-btn-border-width:    2px ;
$input-focus-border-color:  darken(theme-color("primary"), 25%);

$card-border-color:         $brown;
$modal-content-border-color: transparent;

$modal-inner-padding:       0;
$modal-backdrop-bg:         #fff;
$modal-backdrop-opacity:    .8;

$input-group-addon-bg: $light_brown;
