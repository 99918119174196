.optionTitles{

  @extend  .mosaic;

  .option{
    padding-bottom: 92.5714%; //304x280
    @extend .shadowLayer;
  }

  span{
    position:absolute;
    width:80%;
    color: $white;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
}
