.category-item{
	.card{
		height: 250px;
    overflow:hidden;
		display: flex;
		justify-content: center;
    position:relative;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
	}
	&.lg{
		min-height: 425px;
		.card{
			height: 100%;
			display: flex;
			justify-content: center;
		}
	}
}

.latestContent .downloadSingleForm{
  max-height: 390px;
  overflow: scroll;
}

.downloadSingleForm{
  .fileContainer{
    max-height: none;
  }
  .category-item{
    .description{
      p{
        display:none;
      }
      p:nth-of-type(2){
        display:block;
        max-height: 75px;
        overflow: hidden;
      }

    }
  }
}

.modal-content{
  .card{
    position:relative;
    max-height: 600px;
    img{
      width:auto;
      height:100%;
      position:absolute;
      top:0;
      bottom:0;
      left:50%;
      transform: translateX(-50%);
    }
  }
}
