.tab{
  @extend .navigation-item;
  &.active, &:hover{
    border: 2px solid $yellow;
    padding: 0.5rem;
  }
}

.tabs>div:nth-child(1){
  box-shadow: 2px 0 0  $light_brown;
}

.formContainer{
  &>div{
    display:none;
  }
  &>div.active{
    display:block;
  }
}

@mixin line-spacing($lines-spacing: 16%) {

  &:before {
    margin-right:$lines-spacing;
  }

  &:after {
    margin-left:$lines-spacing;
  }

}

.sub-title{

  text-align: center;
  display: flex;
  color: $brown;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:before, &:after {
    flex-grow: 1;
    height: 3px;
    content: '\a0';
    background-color: $light_brown;
    position: relative;
  }

  @include line-spacing(7%);

  &.division{

    $circ-radius: 20px;
    @include line-spacing();

    span{
      width: $circ-radius;
      height:$circ-radius;
      background-color: $light_brown;
      border-radius: 50%;
      @include media-breakpoint-down(sm) {
        $circ-radius: 15px;
        width: $circ-radius;
        height:$circ-radius;
      }
    }

  }

}

.category-item{
  cursor:pointer;
}

@import "optionTitle";
@import "dashboard";
