.search-component{

  outline: 1px solid $light_gray;

  .form-control{
    border:none;
  }

  .input-group-btn{
    &>.btn{
      border:none;
      background: none;
      box-shadow: none;
      color: $brown;
    }
  }

  .input-group-append{
    button{
      position:static;
    }
  }
}


