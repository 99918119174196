.monin-menu{
  background-color: $white;
  @include media-breakpoint-up(sm){
    .menu-item{
    
      &:not(:last-of-type){
        border-right: 3px solid $dark_white;
        padding-right: 17px;
      }
      &:not(:first-of-type){
        padding-left: 13px;
      }
    }
  }
  .navbar-nav .nav-link{
    letter-spacing: 1px;
    font-size: $font-size-sm;
    font-weight: normal;
    @extend .navigation-item;
    @include media-breakpoint-up(sm){
      padding:0;
    }
   
  }

  @include media-breakpoint-up(lg) {
    &.navbar-expand-lg .navbar-collapse{
      justify-content: center;
    }
    .navbar-brand{
      margin-right:0;
      padding: 1.8125rem 0 2rem 0;
    }
  }

  .logout{
    img{
      margin-right: 20px;
    }
  }
}

// Start Breadcrumb
.breadcrumb {
 background-color: inherit;
 margin: 2rem 0 0 0;
 padding: 0
}

.breadcrumb > ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 7px 0 7px;
  justify-content: center;
}

.breadcrumb > ul > li {
  margin-right: 2px;
}

// End Breadcrumb


@include media-breakpoint-up(lg) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    justify-content: center;
    flex-wrap: wrap;
  }
}
