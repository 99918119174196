.dashboardItems{
  @extend  .mosaic;
}

.iconFolder .fa{
  cursor:pointer;
  font-size:1.7rem;
}

.dashItem{
  overflow: hidden;
  height: 360px;
  position: relative;
  cursor: pointer;

  &:before{
    content: '';
    display: block;
    background-color: fade-out($black, .4);
    transition: all .5s;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }
  &.folder{
   &:before {
     background-color: $light_brown;
   }
    &:hover{
      &:before{
        background-color: fade-out($brown, .4);
        transition: all 3s;
      }
    }
  }

  &:after{
    content: '';
    display: block;
    border: 1px solid $light_brown;
    transition: all .5s;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 2;
  }

  .img-bg{
    transition: all .5s;
  }

  &:hover{
    .img-bg{
      transform: scale(1.2);
      transition: all 9s;
    }

    &:before{
      background-color: fade-out(#000, .7);
      transition: all 3s;
    }
    &:after{
      transform: scale(.9);
      transition: all 1s;
    }

  }
}

.dashItem > .contents{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: $white;
  padding: 0 10px;
  height: 49%;
  width:65%;
  opacity: 0.8;
  z-index: 2;
  .icon{
    max-width: 140px;
  }

  span{
    margin-top: 1.5rem;
    display:block;
    color: $dark_brown;
  }
}
.dashItem .img-bg{
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}
